<template>
  <div>
    <a-table
      :dataSource="props.dataSource"
      :columns="props.columns"
      :loading="tableLoading"
      :pagination="false"
      v-bind="$attrs"
    >
      <template v-slot:[item]="scope" v-for="item in renderArr">
        <slot :name="item" :scope="scope"></slot>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, useSlots } from 'vue'

// 插槽处理
const slots = useSlots()
const renderArr = Object.keys(slots)

// props
const props = defineProps({
  // 列配置项
  columns: {
    type: Array,
    default: () => [],
  },
  // 表格数据
  dataSource: {
    type: Array,
    default: () => [],
  },
  /**
   * 处理数据方法
   * 父组件如有处理数据需求，传此方法，需返回结果
   */
  handleData: Function,
})

// 表格数据
const tableLoading = ref(false)
defineExpose({
  dataSource: props.dataSource,
})
</script>

<style lang="scss" scoped></style>

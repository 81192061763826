import request from '@/utils/request'

// 获取菜单数据-树状
export function fatchMenuTree(data) {
  return request({
    url: '/admin/security/menu/tree',
    method: 'get',
    data,
  })
}


// 配置门店
export function hasPermission(data) {
  return request({
    url: '/admin/security/role/hasPermission',
    method: 'post',
    data,
  })
}

// 更新菜单数据
export function fatchUpdateMenuOne(data) {
  return request({
    url: '/admin/security/menu',
    method: 'post',
    data,
  })
}

// 删除菜单数据
export function fatchDeleteMenuOne(data) {
  return request({
    url: '/admin/security/menu',
    method: 'delete',
    data,
  })
}

// 新增菜单数据
export function fatchAddMenuOne(data) {
  return request({
    url: '/admin/security/menu',
    method: 'post',
    data,
  })
}

/**
 * --------------------------角色部分--------------------------
 */
// 获取角色列表-所有
export function fatchRoleList(data) {
  return request({
    url: '/admin/security/role',
    method: 'get',
    data,
  })
}

// 更新角色
export function fatchUpdateRoleOne(data) {
  return request({
    url: '/admin/security/role',
    method: 'put',
    data,
  })
}

// 新增角色
export function fatchAddRoleOne(data) {
  return request({
    url: '/admin/security/role',
    method: 'post',
    data,
  })
}

// 删除角色
export function fatchDeleteRoleOne(data) {
  return request({
    url: '/admin/security/role',
    method: 'delete',
    data,
  })
}

// 查询权限-所有-树状
export function fatchPermissionAll() {
  return request({
    url: '/admin/security/permission/tree',
    method: 'get',
  })
}

// 获取角色对应的权限
export function fatchRolePermission(data) {
  return request({
    url: '/admin/security/role/permission',
    method: 'get',
    data,
  })
}

// 获取角色对应的菜单
export function fatchRoleMenu(data) {
  return request({
    url: '/admin/security/menu/menuRole',
    method: 'get',
    data,
  })
}

// 保存角色对应的权限
export function fatchSaveRolePermission(data) {
  return request({
    url: '/admin/security/role/permission',
    method: 'post',
    data,
  })
}

// 保存角色对应的菜单
export function fatchSaveRoleMenu(data) {
  return request({
    url: '/admin/security/menu/setMenuRole',
    method: 'post',
    data,
  })
}

/**
 * --------------------------员工部分--------------------------
 */
// 查询员工列表-分页
export function queryStaffList(data) {
  return request({
    url: '/admin/security/admin',
    method: 'get',
    data,
  })
}

// 新增员工
export function fatchAddStaffOne(data) {
  return request({
    url: '/admin/security/admin',
    method: 'post',
    data,
  })
}

// 修改员工
export function fatchUpdateStaffOne(data) {
  return request({
    url: '/admin/security/admin',
    method: 'post',
    data,
  })
}

// 删除员工
export function fatchDeleteStaffOne(data) {
  return request({
    url: '/admin/security/admin',
    method: 'delete',
    data,
  })
}

// 重置员工密码
export function fatchResetPwdStaffOne(data) {
  return request({
    url: '/admin/security/admin/reset',
    method: 'post',
    data,
  })
}

// 个人中心-修改密码
export function fatchUpdateUserPwd(data) {
  return request({
    url: '/admin/current/updatePassword',
    method: 'put',
    data,
  })
}

import axios from 'axios'

// 下载流文件
export const downloadFile = (url, method, fileName, data) => {
  axios({
    method: method || 'get',
    url: url,
    data: data,
    responseType: 'blob',
  }).then(res => {
    let blob = new Blob([res.data], {
      type: "application/vnd.ms-excel"    // 下载的文件类型
    });
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.style.display = 'none';
    link.download = fileName
    link.href = url
    document.body.appendChild(link)
    link.click()
  }).catch(error => {
    this.$message.error('下载文件失败')
  });
}
<template>
  <a-config-provider :locale="locale">
    <div>
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

export default {
  data() {
    return {
      locale: zhCN,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
  .search_form_line.between {
    justify-content: flex-start;
    align-items: flex-start;

    .ant-form-item {
      margin-bottom: 14px;
    }
  }
  #nprogress .bar{
    background-color: #f4f4f4!important;
    height:4px!important;
  }
</style>

const state = () => ({
  inStockOrderStatusMap: {
    LAUNCH: '创建中',
    REFUSE: '已拒绝',
    COMMIT: '已提交',
    AUDIT: '入库完成',
    // CONFIRMWARE: '已确认入库',
    CANCEL: '已撤销',
  },
  // 销售单据相关状态
  salesOrderStatusMap: {
    Created: '创建中',
    Submit: '已提交',
    VerifyRefuse: '财务拒绝',
    VerifySuccess: '财务审核通过',
  },
  salesReturnOrderStatusMap: {
    Created: '创建中',
    Submit: '已完成',
  },
  salesOutStockOrderStatusMap: {
    Created: '创建中',
    Outstock: '已出库',
    Submit: '已提交',
  },
  salesInStockStatusMap: {
    Created: '创建中',
    Instock: '已入库',
    Submit: '已提交',
  },

  // 采购单据相关状态
  payTypeStatusList: {
    BeforePay: '预付款',
    ScenePay: '现结',
    MonthPay: '月结',
  },
  orderInvoiceStatusMap: {
    Created: '已创建',
    Picking: '拣货中',
    Distribution: '配货中',
    Complete: '已完成',
  },
  procurementPlansStatusMap: {
    Created: '创建中',
    Submit: '已提交',
  },
  orderPickingStatusMap: {
    Created: "创建中",
    WaitReceive: "待认领",
    Picking: "进行中",
    Complete: "已完成",
  },
  orderDistributeStatusMap: {
    WaitReceive: "待认领",
    Picking: "进行中",
    Complete: "已完成",
  },
  procurementInstockStatusMap: {
    Created: '创建中',
    // Submit: '已提交',
    Instock: '已入库',
  },
  procurementRefundStatusMap: {
    Created: '创建中',
    Submit: '已完成',
  },
  procurementOutstockStatusMap: {
    Created: '创建中',
    Outstock: '已出库',
  },

  // 仓库=> 单据相关状态 
  lossOrderStatusMap: {
    Created: '创建中',
    Loss: '已损耗'
  },
  transferOrderStatusMap: {
    Created: '创建中',
    Transfer: '已调拨',
  },

  outStockOrderStatusMap: {
    STOCKUP: '备货中',
    COMPLETE: '已出库',
    CANCEL: '已撤销',
  },
  lossStockOrderStatusMap: {
    LAUNCH: '创建中',
    COMMIT: '已提交',
    AUDIT: '损耗完成',
    CANCEL: '已撤销',
    REFUSE: '审核拒绝',
  },
  checkStockOrderStatusMap: {
    Created: '待盘点',
    Counting: '盘点中',
    Complete: '盘点完成',
  },
  checkStockOrderTypeMap: {
    FULL: '整仓盘点',
    APPOINT: '指定SKU盘点',
    CATEGORY: '指定分类盘点',
  },
  checkStockResultMap: {
    LOSS: '盘亏',
    EQUAL: '盘平',
    WIN: '盘盈',
  },
  // 财务相关订单状态
  receiptOrderStatusMap: {
    OWE: '欠款',
    SETTLE: '结清',
  },
  advancePaymentOrderStatusMap: {
    OWE: '欠款',
    SETTLE: '结清',
  },
  payTypeMap: {
    WECHAT: '微信支付',
    ALIPAY: '支付宝支付',
    BANK: '银行卡支付',
  },
})
const getters = {
  inStockOrderStatusMap: (state) => state.inStockOrderStatusMap,
  outStockOrderStatusMap: (state) => state.outStockOrderStatusMap,

  salesOrderStatusMap: (state) => state.salesOrderStatusMap,
  salesReturnOrderStatusMap: (state) => state.salesReturnOrderStatusMap,
  salesOutStockOrderStatusMap: (state) => state.salesOutStockOrderStatusMap,
  salesInStockStatusMap: (state) => state.salesInStockStatusMap,

  payTypeStatusList: (state) => state.payTypeStatusList,
  orderInvoiceStatusMap: (state) => state.orderInvoiceStatusMap,
  procurementPlansStatusMap: (state) => state.procurementPlansStatusMap,
  orderPickingStatusMap: (state) => state.orderPickingStatusMap,
  orderDistributeStatusMap: (state) => state.orderDistributeStatusMap,
  procurementInstockStatusMap: (state) => state.procurementInstockStatusMap,
  procurementRefundStatusMap: (state) => state.procurementRefundStatusMap,
  procurementOutstockStatusMap: (state) => state.procurementOutstockStatusMap,

  lossOrderStatusMap: (state) => state.lossOrderStatusMap,
  transferOrderStatusMap: (state) => state.transferOrderStatusMap,

  lossStockOrderStatusMap: (state) => state.lossStockOrderStatusMap,
  checkStockOrderStatusMap: (state) => state.checkStockOrderStatusMap,
  checkStockOrderTypeMap: (state) => state.checkStockOrderTypeMap,
  checkStockResultMap: (state) => state.checkStockResultMap,

  receiptOrderStatusMap: (state) => state.receiptOrderStatusMap,
  advancePaymentOrderStatusMap: (state) => state.advancePaymentOrderStatusMap,
  payTypeMap: (state) => state.payTypeMap,
}
const mutations = {}
const actions = {}
export default { state, getters, mutations, actions }

<template>
  <div @mousedown="onWrapClick">
    <a-select
      :open="open"
      @blur="onBlur"
      style="width: 100%"
      show-search
      v-bind="$attrs"
      :filterOption="filterOption"
      @change="onChange"
    >
      <template #dropdownRender="{ menuNode }">
        <v-nodes :vnodes="menuNode" />
        <a-divider style="margin: 4px 0" />
        <div class="add_ipt">
          <a-input v-model:value="newStr" ref="iptRef" @blur="onInputBlur" @click="onInputClick"> </a-input>
          <a-button type="primary" @click="onAdd" :style="{marginLeft: '6px'}">新增</a-button>
        </div>
      </template>
    </a-select>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import { notification } from 'ant-design-vue';

export default defineComponent({
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes
    },
  },
  props: {
    // 新增选项是否必填
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const { required } = props;
    const newStr = ref('')
    const iptRef = ref(null)
    const open = ref(false)
    const hasClick = ref(false)
    const filterOption = (inputValue, option) => {
      return option.label.indexOf(inputValue) >= 0
    }

    const onBlur = () => {
      setTimeout(() => {
        if (!hasClick.value) {
          open.value = false
        }
      }, 200)
      // open.value = false
    }

    const onInputClick = () => {
      hasClick.value = true
    }

    const onInputBlur = () => {
      hasClick.value = false
      onBlur()
    }

    const onAdd = () => {
      hasClick.value = true
      // 非空校验
      if(required && !newStr.value) {
        notification['error']({
          message: '输入值不能为空',
        });
      }else {
        emit('onAdd', newStr.value)
        setTimeout(() => {
          newStr.value = ''
        }, 0)
      }
      
    }

    const onChange = (val) => {
      open.value = false
      hasClick.value = false
      emit('onChange', val)
    }

    const onWrapClick = () => {
      open.value = true
    }

    return {
      filterOption,
      onWrapClick,
      onBlur,
      onAdd,
      onInputClick,
      onInputBlur,
      onChange,
      newStr,
      iptRef,
      open,
    }
  },
})
</script>

<style lang="scss" scoped>
.add_ipt {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  box-sizing: border-box;
}
</style>

<template>
  <a-layout-header style="height: 64px; background: #2b2f3a">
    <div class="head_main between">
      <div></div>

      <a-dropdown>
        <div class="user_info">
          <a-avatar style="background-color: #1890ff">
            <template #icon><UserOutlined /></template>
          </a-avatar>
          <span class="nickname">{{ name }}</span>
        </div>
        <template #overlay>
          <a-menu @click="onDropMenuClick">
            <a-menu-item key="center">
              <user-outlined />
              个人中心
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="logout">
              <logout-outlined />
              退出登录
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    UserOutlined,
    LogoutOutlined,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const name = computed(() => store.getters['user/name'])

    const onDropMenuClick = ({ key }) => {
      if (key === 'logout') {
        store.dispatch('user/Logout').then((res) => {
          message.success('退出成功')
          router.replace('/login')
        })
      }
      if (key === 'center') {
        router.push('/system/userCenter')
      }
    }
    return {
      name,
      onDropMenuClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.head_main {
  .user_info {
    cursor: pointer;
  }
  .nickname {
    font-size: 14px;
    display: inline-block;
    margin-left: 10px;
    color: #fff;
  }
}
</style>

import { createRouter, createWebHashHistory } from "vue-router";
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});


// router.beforeEach((to, from, next) => {
//   NProgress.start() // 进度条开始
//   next()
// })
 
// router.afterEach(() => {
//   NProgress.done() // 进度条结束
// })

// router/index.ts
 
// NProgress.configure({
//   easing: 'ease', // 动画方式
//   speed: 500, // 递增进度条的速度
//   showSpinner: true, // 是否显示加载 icon
//   trickleSpeed: 200, // 自动递增间隔
//   minimum: 0.3 // 初始化时的最小百分比
// })
export default router;

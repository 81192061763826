import Layout from '@/layout'
/**
 *
 * @param {动态引入路由组件} routes
 * @returns
 */
export function filterRequestRoutes(routes) {
  return routes.map((item) => {
    const currentRouter = {
      path: item.link,
      name: item.name + '-' + item.link,
      hidden: item.hidden || false,
      meta: {
        title: item.name,
        icon: item.icon || '',
      },
      // component(resolve) {
      //   if (item.pid == 0) {
      //     require(["@/layout/index.vue"], resolve);
      //   } else {
      //     require([`@/views${item.link}/index.vue`], resolve);
      //   }
      // },
    }
    if (item.redirect) {
      currentRouter.redirect = item.redirect
    }
    if (item.pid == 0) {
      currentRouter.component = Layout
    } else {
      currentRouter.component = () => import(`@/views${item.link}/index.vue`)
    }
    if (item.children && item.children.length > 0) {
      currentRouter.children = filterRequestRoutes(item.children)
    }
    // console.log('currentRouter:: ', currentRouter);
    return currentRouter
  })
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// 格式化需要label形式的数据
// 一般供下拉框使用
export function formatOptions(list, keyName = 'name', keyValue = 'id', childrenName = 'children') {
  if (Array.isArray(list)) {
    return list.map((v) => {
      v.label = v[keyName]
      v.value = v[keyValue]
      if (v[childrenName] && v[childrenName]['length']) {
        v.children = formatOptions(v[childrenName])
      }
      return v
    })
  } else {
    return []
  }
}

/**
 * 格式化金额显示
 * @param {*} money
 */
export function formatShowMoney(money) {
  money = parseFloat(money).toFixed(2)
  const lowercase = `￥${money}`
  //汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  var cnInteger = '整'
  //整型完以后的单位
  var cnIntLast = '元'
  //最大处理的数字
  var maxNum = 999999999999999.9999
  //金额整数部分
  var integerNum
  //金额小数部分
  var decimalNum
  //输出的中文金额字符串
  var chineseStr = ''
  //分离金额后用的数组，预定义
  var parts
  if (money === '') {
    //不能用==
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    //超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return {
    lowercase,
    uppercase: chineseStr,
  }
}

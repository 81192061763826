<template>
  <a-input-number v-bind="$attrs" :formatter="formatter" style="width: 100%" />
</template>

<script setup>
const formatter = (value) => {
  if (value !== '' && value !== null) {
    return parseInt(value)
  } else {
    return 0
  }
}
</script>

<style lang="scss" scoped></style>

<template>
   <a-button type="primary" @click="handleDownload">
      <template #icon><download-outlined /></template>
      {{ props.name }}
    </a-button>
</template>

<script setup>
import { computed, ref } from 'vue'
import { DownloadOutlined } from '@ant-design/icons-vue'

import { downloadFile } from '@/utils/common.js'

const props = defineProps({
  name: {
    type: String,
    default: '导出'
  },
  actionUrl: {
    type: String,
    default: '',
  },
  params: {
    type: Object,
    default: () => ({}),
  },
  fileName: {
    type: String,
    default: '文件模板',
  }
})

const handleDownload = () => {
  downloadFile(props.actionUrl, 'post', props.fileName, props.params)
}

</script>

<style>

</style>

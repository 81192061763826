<template>
  <a-upload
    name="file"
    :action="props.actionUrl"
    :withCredentials="true"
    :showUploadList="false"
    @change="onUploadChange"
  >
    <a-button type="primary">
      <template #icon><cloud-upload-outlined /></template>
      {{ props.name }}
    </a-button>
  </a-upload>
</template>

<script setup>
import { computed, ref } from 'vue'
import { CloudUploadOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'

const props = defineProps({
  name: {
    type: String,
    default: '导入'
  },
  actionUrl: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['uploadSuccess'])
const uploadLoading = ref(false)

const onUploadChange = (info) => {
  if (info.file.status !== 'uploading') {
    // console.log(info.file, info.fileList);
  }

  if (info.file.status === 'done') {
    if(info.file.response && info.file.response.status === 200) {
      message.error(`${info.file.response.message}`, 5)
    }else {
      message.success(`${info.file.name} 上传成功`, 5)

      emit('uploadSuccess')
    }
    
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} 上传失败`, 5)
    emit('uploadSuccess')
  }
}
</script>

<style>

</style>

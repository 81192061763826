<template>
  <div>
    <div class="add_line between">
      <div>
        <span>{{ props.title || '' }}</span>
      </div>
      <a-space>
        <template v-if="props.address==0">
          <a-button @click="backClick" v-if="props.hasBack"> 返回 </a-button>
        </template>
        <template v-else>
          <a-button @click="routerPage" v-if="props.hasBack"> 返回 </a-button>
        </template>

        <slot name="operateBtns"></slot>

        <a-button type="primary" v-if="props.hasAdd" @click="addClick">
          <template #icon><plus-outlined /></template>
          新增
        </a-button>
      </a-space>
    </div>
    <a-table
      :dataSource="dataSource"
      :columns="props.columns"
      :loading="tableLoading"
      :pagination="false"
      v-bind="$attrs"
    >
      <template v-slot:[item]="scope" v-for="item in renderArr">
        <slot :name="item" :scope="scope"></slot>
      </template>
    </a-table>
    <div class="pager_wrap" v-if="pagination">
      <a-pagination
        v-model:current="pager.data.current"
        v-model:pageSize="pager.data.pageSize"
        :total="pager.data.total"
        show-less-items
        show-size-changer
        :show-total="(total) => `总 ${total} 条`"
        @change="pagerChange"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, useSlots, toRaw, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { PlusOutlined } from '@ant-design/icons-vue'

// 插槽处理
const slots = useSlots()
const emit = defineEmits(['onAdd', 'queryEnd'])
const renderArr = Object.keys(slots)
const router = useRouter()

// props
const props = defineProps({
  // 列配置项
  columns: {
    type: Array,
    default: () => [],
  },
  // 筛选条件表单数据
  queryForm: {
    type: Object,
    default: {},
  },
  // 查询Api
  queryApi: Function,
  /**
   * 处理数据方法
   * 父组件如有处理数据需求，传此方法，需返回结果
   */
  handleData: Function,
  // 书否组件挂载就请求数据
  isMounted: {
    type: Boolean,
    default: true,
  },
  // 是否需要分页
  pagination: {
    type: Boolean,
    default: true,
  },
  // 表格左侧标题
  title: {
    type: String,
    default: '',
  },
  // 是否展示新增按钮
  hasAdd: {
    type: Boolean,
    default: true,
  },
  // 是否展示返回按钮
  hasBack: {
    type: Boolean,
    default: false,
  },
  address: {
   type: String,
   default: '0',
  },
  params:{
	 type: String,
	 default: '', 
  },
})

const columnsRenderArr = computed(() => {
  return [
    {
      title: '序号',
      dataIndex: 'indexNum',
      key: 'indexNum',
      align: 'center',
      customRender: (row) => (row.index + 1)
    },
    ...props.columns
  ]
})

// 分页数据
const pager = reactive({
  data: {
    pageSize: 50,
    current: 1,
    total: 0,
  },
})
// 页码或者尺寸改变
const pagerChange = (page, pageSize) => {
  pager.data.pageSize = pageSize
  pager.data.current = page
  queryData()
}

// 表格数据
const dataSource = ref([])
const tableLoading = ref(false)

const queryData = () => {
	
  if (props.queryApi) {
    tableLoading.value = true
    const params = {}
    if (props.pagination) {
      params.current = pager.data.current
      params.pageSize = pager.data.pageSize
    }
    const queryFormData = toRaw(props.queryForm)
    props
      .queryApi({
        ...params,
        ...queryFormData,
      })
      .then((res) => {
		  
        if (res.status === 0) {
          const list = res.data || []
          dataSource.value = props.handleData ? props.handleData(list) : list
          if (props.pagination) {
            pager.data = res.pagination
          }
          tableLoading.value = false
          emit('queryEnd', list)
        } else {
          tableLoading.value = false
        }
      })
      .catch((err) => {
        console.log('err:', err)
        tableLoading.value = false
      })
  }
}

// 表格重载
const reload = () => {
  pager.data = {
    pageSize: 50,
    current: 1,
    total: 0,
  }
  queryData()
}

// 新增点击
const addClick = () => {
  emit('onAdd')
}

// 返回点击
const backClick = () => {
  router.back()
}

// 带参数返回按钮与上个方法服务一个按钮
const routerPage=()=>{
	// console.log('props',props)
	if(props.address==0)return;
	router.push({
	  path:props.address,
	  query:{
		  serach:props.params
	  }
	})
}

// 组件挂载
onMounted(() => {
  if (props.isMounted) {
    queryData()
  }
})

// 暴露组件方法
defineExpose({
  reload,
  dataSource,
  queryData 
})
</script>

<style lang="scss" scoped>
.add_line {
  margin-bottom: 10px;
}
.pager_wrap {
  margin-top: 20px;
  text-align: right;
}

:deep(.ant-table-container) {
  height: calc(100vh - 360px);
  overflow-y: auto;
}
</style>

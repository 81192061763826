import { 
  fatchRegionTree, 
  queryCategoryTreeData, 
  queryWarehouseListAll,
  queryCustAll,
  querySupplierListAll,
  fatchQueryCategoryAll,
  stockList
} from '@/api/base'
import { fatchRoleList } from '@/api/system'
import { setItem } from '@/utils/storage'

const state = () => ({
  // 省市区数据
  regionTreeData: [],
  // 所有商品分类数据
  categoryAll: [],
  categoryNotTree: [],
  // 获取全部角色
  roleAll: [],
  // 全部仓库数据
  stockAll: [],
  // 仓库库位级联数据
  stockTreeDataAll: [],
  // 客户
  customerAll: [],
  // 供应商
  supplierAll: [],
  // 当前门店
  stockListOne:[]
})
const getters = {
  categoryAll: (state) => state.categoryAll,
  categoryNotTree: (state) => state.categoryNotTree,
  roleAll: (state) => state.roleAll,
  stockAll: (state) => state.stockAll,
  customerAll: (state) => state.customerAll,
  supplierAll: (state) => state.supplierAll,
  stockTreeDataAll: (state) => state.stockTreeDataAll,
  stockListOne:(state) => state.stockListOne
}
const mutations = {
  // 设置省市区数据
  setRegionTreeData(state, list) {
    state.regionTreeData = list
  },
  // 设置分类数据-all
  setCategory(state, payload) {
    state.categoryAll = payload
  },
  setCategoryNotTree(state, payload) {
    state.categoryNotTree = payload
  },
  // 设置角色
  setRole(state, payload) {
    state.roleAll = payload
  },
  // 设置仓库
  setStock(state, payload) {
    state.stockAll = payload
  },
  // 设置客户
  setCustomer(state, payload) {
    state.customerAll = payload
  },
  // 设置供应商
  setSupplier(state, payload) {
    state.supplierAll = payload
  },
  // 仓库库位Tree结构
  setStockTree(state, payload) {
    state.stockTreeDataAll = payload
  },
  setStockOne(state, payload){
    state.stockListOne= payload
  }
    
}
const actions = {
  // 请求省市区数据
  async Get_Region({ commit }, userInfo) {
    fatchRegionTree().then((res) => {
      if (res.status === 0) {
        setItem('regionTreeData', res.data)
      }
    })
  },
  // 请求分类全部数据
  async Get_Category_All({ commit }, payload) {
    const res = await queryCategoryTreeData()
    if (res.status === 0) {
      const targetArr = res.data
      commit('setCategory', targetArr || [])
    }
  },
  async Get_Category_All_Not_Tree({ commit }, payload) {
    const res = await fatchQueryCategoryAll({ pid: 0 })
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
        }
      })
      commit('setCategoryNotTree', targetArr || [])
    }
  },
  // 请求角色全部数据
  async Get_Role_All({ commit }, payload) {
    const res = await fatchRoleList()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.roleKey,
        }
      })
      commit('setRole', targetArr || [])
    }
  },
  // 请求仓库全部数据
  async Get_Stock_All({ commit }, payload) {
    const res = await queryWarehouseListAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
          code:v.code
        }
      })
      commit('setStock', targetArr || [])
    }
  },

    // 请求当前门店仓库
    async Get_Stock_One({ commit }, payload) {
      const res = await stockList()
      if (res.status === 0) {
        const targetArr = res.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
            code:v.code
          }
        })
        commit('setStockOne', targetArr || [])
      }
    },
  // 请求客户
  async Get_Customer_All({ commit }, payload) {
    const res = await queryCustAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
          ...v
        }
      })
      commit('setCustomer', targetArr || [])
    }
    return res;
  },
  // 请求供应商
  async Get_Supplier_All({ commit }, payload) {
    const res = await querySupplierListAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
          ...v
        }
      })
      commit('setSupplier', targetArr || [])
    }
    return res;
  },
  // 请求仓库库位级联数据
  async Get_Stock_Tree_All({ commit }, payload) {
    const res = await querySupplierListAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
          ...v
        }
      })
      commit('setStockTree', targetArr || [])
    }
    return res;
  },
}
export default { state, getters, mutations, actions }

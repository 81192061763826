<template>
  <a-layout-sider v-model:collapsed="collapsed" collapsible>
    <Logo />

    <div class="scrollContent">
      <a-menu 
        v-model:selectedKeys="menuSelectedKeys" v-model:openKeys="menuOpenKeys" 
        theme="dark" mode="inline"
        @openChange="openChange"
      >
        <template v-for="(menu, index) in menuList" :key="index">
          <a-sub-menu :key="menu.path" v-if="!menu.hidden">
            <template #title>
              <span>
                <component :is="menu.iconTag ? menu.iconTag : ''" />
                <span>{{ menu.meta && menu.meta.title }}</span>
              </span>
            </template>
            <template v-for="childMenu in menu.children" :key="childMenu.path">
              <router-link :to="childMenu.path" v-if="!childMenu.hidden">
                <a-menu-item :key="childMenu.path">
                  {{ childMenu.meta && childMenu.meta.title }}
                </a-menu-item>
              </router-link>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </div>
  </a-layout-sider>
</template>

<script>
import { defineComponent, ref, computed, onCre } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Logo from './Logo.vue'
import * as AntdIcon from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    Logo,
    // ...AntdIcon
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const collapsed = ref(false)
    const menuSelectedKeys = ref([route.path])
    const menuOpenKeys = ref([route.matched[0].path])
    const menuList = computed(() => {
      return (store.getters['user/menuList']).map(v => ({
        ...v,
        iconTag: v.meta.icon ? AntdIcon[v.meta.icon] : ''
      }))
    })
    const openChange = (openKeys) => {
      if(openKeys.length === 0) return ;
      menuOpenKeys.value = [openKeys[openKeys.length - 1]]
    }

    return {
      collapsed,
      menuList,
      menuOpenKeys,
      menuSelectedKeys,
      openChange
    }
  },
})
</script>

<style lang="scss">
  /** 自定义侧边栏滚动条样式 */
  .scrollContent {
    max-height: calc(100vh - 112px);
    overflow: hidden auto;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px;
      height: 6px;
      background: rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-track {
      background: #ffffff26;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
      box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
    }
    
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      background: #fff3;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
      box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
    }
  }
</style>
